import { Icon, mdBumps, Typo, WriskTheme } from '@wrisk/ui-components'

import logo from './assets/icons/logo.svg'
import jaguarSubscription from './assets/images/jaguar-subscription.jpg'
import fontFaces from './fonts'
import icons from './icons'
import illus from './illus'

const jaguarColors = {
  white: '#FFFFFF',
  primary600: '#3C3C3B',
  primary50: '#D5D5D4',
  gray900: '#141414',
  gray800: '#3C3C3B',
  gray600: '#444444',
  gray500: '#4F4F4E',
  gray400: '#666666',
  gray300: '#8D8D8D',
  gray200: '#E3E3E3',
  gray100: '#F0F0F0',
  gray50: '#F9F9F9',
  alert600: '#D2000A',
  alert50: '#FDF2F3',
  success600: '#058A30',
  success50: '#F2F9F5',
  warning600: '#FF7F00',
  warning50: '#FFF9F2',
}

const typoSizes = {
  xxl: {
    fontSize: ['19px', '19px', '23px', '27px'],
    lineHeight: ['20px', '20px', '23px', '27px'],
    letterSpacing: 0,
  },
  xl: {
    fontSize: ['20px', '20px', '24px', '24px'],
    lineHeight: ['24px', '24px', '26px', '26px'],
    letterSpacing: '0.15em',
  },
  lg: {
    fontSize: ['18px', '18px', '20px'],
    lineHeight: ['22px', '22px', '24px'],
    letterSpacing: '0.15em',
  },
  md: {
    fontSize: ['16px', '16px', '18px'],
    lineHeight: ['20px', '20px', '22px'],
    letterSpacing: '0.15em',
  },
  base: {
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.16px',
  },
  sm: {
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.16px',
  },
  xs: {
    fontSize: '12px',
    lineHeight: '20px',
    letterSpacing: '0.16px',
  },
}

export const jaguarTheme: WriskTheme = {
  // Common
  name: 'Jaguar',
  illus,
  fontFaces,
  icons,
  logo: {
    regular: logo,
  },
  breakpoints: ['480px', '768px', '1024px', '1200px', '1600px'],
  space: [
    '0rem', //  0    0px
    '0.25rem', //  1    4px
    '0.5rem', //  2    8px
    '0.75rem', //  3   12px
    '1.0rem', //  4   16px
    '1.25rem', //  5   20px
    '1.5rem', //  6   24px
    '1.75rem', //  7   28px
    '2.0rem', //  8   32px
    '2.5rem', //  9   40px
    '3.0rem', // 10   48px
    '3.5rem', // 11   56px
    '4.0rem', // 12   64px
    '4.5rem', // 13   72px
    '5.0rem', // 14   80px
  ],
  radii: [0, 0, 0, 0],
  lineHeights: ['1em', '1.1em', '1.2em', '1.3em', '1.4em', '1.5em'],
  fonts: {
    body: `'Proxima Nova', sans-serif`,
    header: `'Proxima Nova', sans-serif`,
    navbar: `'Proxima Nova', sans-serif`,
    buttons: `'Proxima Nova', sans-serif`,
    input: `'Proxima Nova', sans-serif`,
    licencePlate: `'UKLicencePlate', sans-serif`,
  },
  typoSizes,
  shadows: {
    // TODO (MK): Check usages of shadows
    default: `0 0 0 1px ${jaguarColors.gray200}`,
    hover: `0 0 0 1px ${jaguarColors.gray400}`, // Can be removed
    input: `0 0 0 2px ${jaguarColors.primary600}`,
    inset: `inset -8px 0 0 0 ${jaguarColors.white}, inset 8px 0 0 0 ${jaguarColors.white}`,
    popupMenu: '0 4px 32px 0 rgba(0, 0, 0, 0.06)',
    radioNested: `inset 0 0 0 1px ${jaguarColors.gray200}`,
    radioStandalone: `inset 0 0 0 1px ${jaguarColors.gray200}`,
    radioHover: `inset 0 0 0 1px ${jaguarColors.gray800}`,
    radioChecked: `inset 0 0 0 2px ${jaguarColors.gray800}`,
  },
  transitions: {
    default: 'all 250ms ease',
  },
  navigation: {
    height: ['48px', '48px', '72px'],
  },
  colors: {
    chalk: jaguarColors.white,
    background: jaguarColors.gray50,
    body: jaguarColors.gray900,
    bodySecondary: jaguarColors.gray600,
    hyperlink: jaguarColors.gray900,
    focus: jaguarColors.gray900,
    hover: jaguarColors.gray50,

    primaryAccent: jaguarColors.primary600,
    secondaryAccent: jaguarColors.primary600,

    foregroundPromoBanner: jaguarColors.white,
    backgroundPromoBanner: jaguarColors.gray900,

    foregroundPromoActivated: jaguarColors.white,
    backgroundPromoActivated: jaguarColors.success600,

    textOnNavigation: jaguarColors.gray900,
    surfaceNavigation: jaguarColors.white,

    textInfo: jaguarColors.primary600,
    surfaceInfoSubdued: jaguarColors.primary50,
    surfaceInfo: jaguarColors.primary600,

    textCritical: jaguarColors.alert600,
    textOnCritical: jaguarColors.alert600,
    surfaceCritical: jaguarColors.alert600,
    surfaceCriticalHovered: jaguarColors.alert50,
    surfaceCriticalSubdued: jaguarColors.alert50,

    textWarning: jaguarColors.warning600,
    textOnWarning: jaguarColors.warning600,
    surfaceWarning: jaguarColors.warning600,
    surfaceWarningSubdued: jaguarColors.warning50,

    textHighlight: jaguarColors.gray600,
    textOnHighlight: jaguarColors.gray600,
    surfaceHighlight: jaguarColors.gray100,
    surfaceHighlightSubdued: jaguarColors.gray50,

    textSuccess: jaguarColors.success600,
    textOnSuccess: jaguarColors.success600,
    surfaceSuccess: jaguarColors.success600,
    surfaceSuccessSubdued: jaguarColors.success50,

    border: jaguarColors.gray200,
    divider: jaguarColors.gray200,
    inactive: jaguarColors.gray600,
    placeholder: jaguarColors.gray300,

    textDisabled: jaguarColors.gray600,
    surfaceDisabled: jaguarColors.gray100,

    dividerFooter: '#353534',
    textFooter: jaguarColors.white,
    surfaceFooter: jaguarColors.primary600,

    radioCheckedBackground: jaguarColors.gray800,
    radioCheckedForeground: jaguarColors.white,

    dragBoxBorderActive: '#4F46E5',
    dragBoxBorderDefault: '#4B5563',

    progressBackgroundColor: '#9CA3AF',
    progressBarColor: '#4F46E5',
  },
  proposalImages: {
    'jaguar-subscription': jaguarSubscription,
  },
  promoImages: {},
  containerStyle: {
    default: {},
    raised: {
      backgroundColor: jaguarColors.white,
      borderStyle: 'solid',
      borderColor: 'border',
      borderWidth: '1px',
    },
    prominent: {},
  },
  // Components
  Button: {
    base: {
      textTransform: 'uppercase',
      transition: 'all 400ms ease',
      letterSpacing: '1.44px',
    },
    layout: {
      default: {
        px: 6,
        py: '17px',
        fontSize: '12px',
        lineHeight: '14px',
      },
      fixed: {
        px: 6,
        py: '17px',
        fontSize: '12px',
        lineHeight: '14px',
        width: '85%',
        maxWidth: '262px',
      },
      small: {
        px: 4,
        py: '11px',
        fontSize: '12px',
        lineHeight: '14px',
      },
    },
  },
  PrimaryButton: {
    variants: {
      default: {
        bg: jaguarColors.primary600,
        color: jaguarColors.white,
        '&:hover:not([disabled])': {
          boxShadow: `inset 0 0 0 1px ${jaguarColors.primary600}`,
          bg: jaguarColors.white,
          color: jaguarColors.primary600,
        },
      },
      inverted: {
        bg: jaguarColors.white,
        color: jaguarColors.gray900,
        '&:hover:not([disabled])': {
          boxShadow: 'none',
          bg: 'rgba(255,255,255,0.36)',
        },
      },
      critical: {
        bg: jaguarColors.primary600,
        color: jaguarColors.white,
        '&:hover:not([disabled])': {
          boxShadow: `inset 0 0 0 1px ${jaguarColors.primary600}`,
          bg: jaguarColors.white,
          color: jaguarColors.primary600,
        },
      },
    },
  },
  SecondaryButton: {
    variants: {
      default: {
        bg: jaguarColors.gray100,
        color: jaguarColors.primary600,
        '&:hover:not([disabled])': {
          boxShadow: `inset 0 0 0 1px ${jaguarColors.primary600}`,
          bg: jaguarColors.white,
          color: jaguarColors.primary600,
        },
      },
      inverted: {
        bg: jaguarColors.primary600,
        color: jaguarColors.gray100,
        '&:hover:not([disabled])': {
          boxShadow: `inset 0 0 0 1px ${jaguarColors.white}`,
          bg: jaguarColors.primary600,
          color: jaguarColors.white,
        },
      },
    },
  },
  TertiaryButton: {
    variants: {
      default: {
        boxShadow: `inset 0 0 0 1px ${jaguarColors.gray300}`,
        color: jaguarColors.gray900,
        '&:hover:not([disabled])': {
          bg: jaguarColors.gray100,
        },
      },
      inverted: {
        bg: jaguarColors.primary600,
        color: jaguarColors.gray100,
        '&:hover:not([disabled])': {
          boxShadow: `inset 0 0 0 1px ${jaguarColors.white}`,
          bg: jaguarColors.primary600,
          color: jaguarColors.white,
        },
      },
    },
  },
  IconButton: {
    variants: {
      default: {
        '&:hover': {
          backgroundColor: 'hover',
        },
      },
      primary: {
        bg: jaguarColors.primary600,
        color: jaguarColors.white,
        '&:hover:not([disabled])': {
          boxShadow: `inset 0 0 0 1px ${jaguarColors.primary600}`,
          bg: jaguarColors.white,
          color: jaguarColors.primary600,
          [Icon]: {
            path: {
              fill: jaguarColors.primary600,
            },
            ellipse: {
              fill: jaguarColors.primary600,
            },
          },
        },
        [Icon]: {
          path: {
            fill: jaguarColors.white,
          },
          ellipse: {
            fill: jaguarColors.white,
          },
        },
      },
    },
  },
  Heading: {
    default: {
      textTransform: 'uppercase',
    },
    h1: {
      fontFamily: `'Jaguar Modern', sans-serif`,
      textTransform: 'uppercase',
    },
    h2: {
      textTransform: 'uppercase',
    },
    h3: {
      textTransform: 'uppercase',
    },
    h4: {
      textTransform: 'uppercase',
    },
  },
  Typo: {
    default: {},
    heading: {
      fontFamily: 'header',
      textTransform: 'uppercase',
    },
    buttons: {
      fontFamily: 'buttons',
    },
  },
  TabAnchor: {
    active: {
      textTransform: 'uppercase',
      py: 4,
      color: jaguarColors.gray900,
      borderColor: jaguarColors.primary600,
      borderBottomWidth: 3,
      fontSize: '11px',
      lineHeight: '16px',
      letterSpacing: '1.76px',
    },
    inactive: {
      textTransform: 'uppercase',
      py: 4,
      color: jaguarColors.gray400,
      borderColor: 'transparent',
      borderBottomWidth: 3,
      fontSize: '11px',
      lineHeight: '16px',
      letterSpacing: '1.76px',
      '&:hover': {
        color: jaguarColors.gray900,
      },
    },
  },
  LinkAnchor: {
    standalone: {
      textTransform: 'uppercase',
      letterSpacing: '1.44px !important',
      color: jaguarColors.primary600,
      borderColor: 'transparent',
      borderBottomWidth: 1,
      '&:hover': {
        borderColor: jaguarColors.gray900,
        color: jaguarColors.gray900,
      },
    },
    inline: {
      '&:hover': {
        color: jaguarColors.primary600,
        borderColor: jaguarColors.primary600,
      },
    },
  },
  FooterAnchor: {
    default: {
      textTransform: 'uppercase',
      letterSpacing: '1.44px',
      fontSize: '12px',
      lineHeight: '14px',
      fontWeight: 'bold',
      color: jaguarColors.white,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  Logo: {
    default: {
      height: ['48px', '48px', '66px'],
      marginTop: '-2px',
    },
  },
  NavBar: {
    default: {
      bg: jaguarColors.primary600,
      boxShadow: ['none', 'none', 'rgba(0, 0, 0, 0.25) 0px 2px 4px 0px'],
      pl: [0, 0, 0, 0],
      pr: [2, 2, 4, 4],
    },
  },
  AppName: {
    default: {
      borderWidth: 0,
      borderLeftWidth: 1,
      borderColor: jaguarColors.white,
      borderStyle: 'solid',
      paddingLeft: ['20px', '20px', '28px'],
      textTransform: 'uppercase',
      fontFamily: `'Jaguar Modern', sans-serif`,
      marginTop: '-2px',
      color: jaguarColors.white,
      letterSpacing: '1.44px',
      fontSize: ['8px', '8px', '10px'],
      lineHeight: ['10px', '10px', '12px'],
    },
  },
  AccountIcon: {
    default: {
      bg: jaguarColors.white,
      [Typo]: {
        color: jaguarColors.gray900,
      },
    },
  },
  HelpText: {
    default: {
      color: jaguarColors.white,
      textTransform: 'uppercase',
      fontFamily: 'navbar',
      letterSpacing: '1.76px',
      fontSize: '11px',
      lineHeight: '16px',
    },
  },
  NavAnchor: {
    default: {
      height: (theme: WriskTheme): (string | number)[] => theme.navigation.height,
      fontWeight: 'bold',
      '&:hover': {
        backgroundColor: jaguarColors.gray500,
      },
      [Icon]: {
        path: {
          fill: jaguarColors.white,
        },
      },
    },
    inverted: {
      fontWeight: 'bold',
    },
  },
  StyledPremium: {
    default: {
      letterSpacing: '1.5px !important',
    },
    inverted: {
      letterSpacing: '1.5px !important',
      bg: 'transparent',
      color: jaguarColors.gray900,
    },
  },
  LicencePlate: {
    default: {
      letterSpacing: '0px !important',
      backgroundColor: '#ffd067',
      color: 'body',
    },
    subtle: {
      letterSpacing: '0px !important',
      boxShadow: `inset 0 0 0 1px ${jaguarColors.gray900}`,
      color: 'body',
    },
  },
  TextArea: {
    default: {
      boxShadow: 'default',
      p: mdBumps,
      bg: 'chalk',
      '&:focus': {
        boxShadow: 'input',
      },
    },
  },
  TextInput: {
    default: {
      boxShadow: 'default',
      p: mdBumps,
      bg: 'chalk',
      '&:focus': {
        boxShadow: 'input',
      },
    },
    ghost: {
      boxShadow: 'none',
      py: mdBumps,
      px: 0,
    },
    licencePlate: {
      letterSpacing: 'normal !important',
      fontFamily: 'licencePlate',
      boxShadow: 'none',
      py: mdBumps,
      px: 0,
    },
  },
}
